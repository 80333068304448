import React from 'react';

const HintMessage = ({ message = '' }) => {
  if (!message) return null;
  return (
    <>
      {message && (
        <div className='error-txt text-white text-left text-xs w-full flex items-center'>
          <p> {message }</p>
        </div>
      )}
    </>
  );
};

export default HintMessage;
