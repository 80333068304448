import useIsUserInteract from 'Hook/useIsUserInteract';
import { MemoVideoAnimation } from './VideoAnimation';

const Adaywithblack = () => {
  const isIntersecting = useIsUserInteract();
  if (!isIntersecting) {
    return null;
  }
  return (
    <div className='a-day-with-black-container'>
      <MemoVideoAnimation />
    </div>
  );
};

export default Adaywithblack;
