export const ROUTE_LIST = {
  HOME: '/',
  HOME_PAGE: '/home',
  MAIN: '/main',
  APP_HOME: '/app-mb',
  FAQ: '/faqs',
  FAQ_TYPE: '/faqs/:type',
  LEGAL: '/legal',
  LEGAL_TYPE: '/legal/:type',
  CONTACT_US: '/contactus',
  CAREERS: '/careers',
  JOB_PAGE: '/job/:id',
  ABOUT_US: '/aboutus',
  NEWS: '/news',
  MEDIA: '/media',
  INVESTORS: '/investors',
  PROFILE: '/profile',
  BOOKING: '/booking',
  NOT_FOUND: '*',

  EMAIL_ADDRESS: '/email-address',
  COMPENDIUM: '/compendium',
  GRATIAS_TIBI_AGO: '/gratias-tibi-ago',
  SMART_FIELD: '/smart-field',
  REFINED_SELECTION: '/refined-selection',
  PHONE_NUMBER: '/phone-number',
  AT_YOUR_CONVENIENCE: '/at-your-convenience',
  A_DAY_WITH_BLACKJET: '/a-day-with-blackjet',
  PHONE_ONBOARDING: '/phone-onboarding',
  VIRTUAL_VIEW: '/virtual-view',
  VIRTUAL_VIEW_OPEN: '/virtual-tour',
  MOBILE_ANIMATION: 'mobile-animation',

  // outer route
  REFER: '/refer/:id',
  GUEST_INVITE: '/invite/:id',
  SHARED_FLIGHT: '/shared-flight/:id',

  // Email verification
  EMAIL_VERIFICATION_PASS: '/email-verification/pass',
  EMAIL_VERIFICATION_FAIL: '/email-verification/fail/:id',

  // pwa
  // SPLASH: '/app',
  GET_APP: '/getapp',
};

const appRedirectUrl = import.meta.env.VITE_APP_BASE_URL;
export const PWA_REDIRECTION_LINK = {
  MEMBERSHIP: `${appRedirectUrl}/app/#/membership_plan`,
  FAQ: `${appRedirectUrl}/app/#/faq`,
  REDIRECTION: `${appRedirectUrl}/app/#/pwa`,
};

console.log(PWA_REDIRECTION_LINK, 'this_is_PWA_REDIRECTION_LINK');

export const pwaRedirect = (token) => {
  const getLocalStorageToken = localStorage?.getItem('blackjet-website');
  if (token) {
    const url = `${PWA_REDIRECTION_LINK.REDIRECTION}/${token}`;
    console.log(url, 'this_is_url');
    window?.location.replace(url);
    return;
  } else {
    const url = `${PWA_REDIRECTION_LINK.REDIRECTION}/${getLocalStorageToken}`;
    console.log(url, 'this_is_url');
    window?.location.replace(url);
  }
};
