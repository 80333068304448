import { Outlet } from 'react-router';

const AppLayout = () => {


  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AppLayout;
