import { useRef, useState } from 'react';

interface UseIsTypingOptions {
  onStartTyping: () => void; // Callback when typing starts
  onStopTyping: () => void; // Callback when typing stops
  debounceTime?: number; // Debounce delay (default: 1000ms)
}

const useIsTyping = ({ onStartTyping, onStopTyping, debounceTime = 1000 }: UseIsTypingOptions) => {
  const [isTyping, setIsTyping] = useState(false); // Tracks if the user is currently typing
  // eslint-disable-next-line no-undef
  const debounceTimer = useRef<NodeJS.Timeout | null>(null); // Ref to store the debounce timer

  const handleTyping = () => {
    if (!isTyping) {
      setIsTyping(true);
      onStartTyping(); // Trigger typing start callback
    }

    // Clear the previous debounce timer, if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer for stopping typing
    debounceTimer.current = setTimeout(() => {
      setIsTyping(false);
      onStopTyping(); // Trigger typing stop callback
    }, debounceTime);
  };

  return { handleTyping, isTyping };
};

export default useIsTyping;
