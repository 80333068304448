const documentIcon = 'https://assets.blackjet.au/images/document.svg';
const photoAtt = 'https://assets.blackjet.au/images/photoAtt.svg';
const takePhoto = 'https://assets.blackjet.au/images/takePhoto.svg';
const iconEdit = 'https://assets.blackjet.au/images/edit.svg';
const iconRestore = 'https://assets.blackjet.au/images/restore.svg';
const checkIcon = 'https://assets.blackjet.au/images/checkIcon.svg';
const circleIcon = 'https://assets.blackjet.au/images/circleIcon.svg';
const circleIconDisabled = 'https://assets.blackjet.au/images/checkIconDisabled.svg';

export {
  documentIcon,
  circleIconDisabled,
  photoAtt,
  takePhoto,
  iconEdit,
  iconRestore,
  checkIcon,
  circleIcon,
};
