const CareerHeader = () => {
  return (
    <div>
      <div className='career-wrap'>
        <div className='flex flex-col gap-6  '>
          <div className='career-heading'>Work with us</div>
          <div className='career-p'>
            Join our team in shaping the future of aviation—more personal, stress-free, and flexible
            than ever. Every day, we refine, iterate and explore how to make personal aviation
            better for everyone.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerHeader;
