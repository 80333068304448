import { useState, useEffect, useCallback } from "react";

const useCountdownTimer = (storageKey: string) => {
  const [counter, setCounter] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    const savedCounter = localStorage.getItem(storageKey);
    const startTime = localStorage.getItem(`${storageKey}-startTime`);

    if (savedCounter && startTime) {
      const initialTime = parseInt(savedCounter, 10);
      const startTimestamp = parseInt(startTime, 10);
      const elapsedTime = Math.floor((Date.now() - startTimestamp) / 1000);
      const remainingTime = Math.max(initialTime - elapsedTime, 0);

      setCounter(remainingTime);
      setIsRunning(remainingTime > 0);
    }
  }, [storageKey]);

  useEffect(() => {
    if (!isRunning || counter <= 0) return;

    const interval = setInterval(() => {
      setCounter((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          localStorage.removeItem(`${storageKey}-running`);
          localStorage.removeItem(`${storageKey}-startTime`);
          return 0;
        }
        const newCount = prev - 1;
        return newCount;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isRunning, counter, storageKey]);

  const startCountdown = useCallback((initialValue: number) => {
    const currentTime = Date.now();

    localStorage.setItem(storageKey, initialValue.toString());
    localStorage.setItem(`${storageKey}-startTime`, currentTime.toString());
    setCounter(initialValue);
    setIsRunning(true);
  }, [storageKey]);

  return { counter, startCountdown };
};

export default useCountdownTimer;
