import React from 'react';

const Img = ({
  className,
  src = 'defaultNoData.png',
  alt = 'testImg',
  srcSet = [],
  pictureClass = '',
  ...restProps
}) => {
  // Ensure srcSet items use `srcSet` instead of `src`
  const sources = [...srcSet.map((src) => ({ srcSet: src }))];

  return (
    <picture className={pictureClass}>
      {sources.map((item, index) => (
        <source {...item} key={index} />
      ))}
      <img className={className} src={src} alt={alt} {...restProps} loading='lazy' />
    </picture>
  );
};

export { Img };
