export const systemMessage = {
  // system generated message
  _id: '678608b9d174088849a6c4d0',
  sender_type: 'subadmin',
  sender: 'null',
  receiver: 'null',
  message:
    'Hi there!  I’m here to assist you with any questions or concerns you might have.  How can I help you today?',
  media: [],
  message_type: 'text',
  user_name: 'Sub Admin',
  profile_pic: '',
  createdAt: '2025-01-14T06:48:17.602Z',
  type: true,
  fully_disconnected: true,
  readStatus: 'read',
};
