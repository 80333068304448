import { LinearProgress, useMediaQuery } from '@mui/material';
import useScrollToTop from 'Hook/useScrollToTop';

import Footer from 'components/Footer';
import FooterNavbar from 'components/Footer/FooterNavbar';
import Header from 'components/Header';
import { FAQ_CATEGORY, LEGAL_CATEGORY } from 'constants/actions';
import { useBlackJetContext } from 'context/OnboardingContext';
import NotFound from 'pages/NotFound';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { ROUTE_LIST } from 'routes/routeList';
import { getCategoryList, getviewAllLegal } from 'services/api';
import { isDesktopValidRoute, isMobileValidRoute } from 'utils';

/**
 * CommonLayout component
 *
 * This component renders the Header, Footer, FooterNavbar and the content of the page.
 * It also handles the logic of showing/hiding the Header and Footer based on the current route.
 *
 * @param {boolean} open - prop to control the state of the header
 * @param {function} setOpen - function to set the state of the header
 * @returns {ReactElement} - the rendered component
 */
const CommonLayout = ({ open, setOpen }) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:699px)');
  const { onboardingForms, dispatchOnboardingForms } = useBlackJetContext();
  const faqCatData = onboardingForms?.faqCategory;
  const legalCatData = onboardingForms?.legalCategory;
  const getLocalStorage =
    typeof window !== 'undefined' && JSON.parse(localStorage?.getItem('user_details') || '{}');
  const navigate = useNavigate();
  const pathname = location.pathname;
  const params = useParams();
  useScrollToTop();

  const isShowHeaderFooter = () => {
    if (
      isMobile &&
      (pathname === ROUTE_LIST.FAQ ||
        pathname === ROUTE_LIST.LEGAL ||
        pathname === ROUTE_LIST.CONTACT_US ||
        pathname === ROUTE_LIST.ABOUT_US ||
        pathname === ROUTE_LIST.PHONE_ONBOARDING)
    ) {
      return false;
    }
    return true;
  };

  const checkPwaWithMob = () => {
    if (typeof window !== 'undefined') {
      return;
    }
    if (isMobile && getLocalStorage?.user_token && location.pathname !== '/app/') {
      // if(){}
      navigate('/app/#/home');
      window?.location.reload();
      return;
    }
  };

  const pathSegments = location.pathname.split('/');
  const getFaqCategory = async () => {
    try {
      let res = '';
      if (pathSegments[1] === 'faqs' && !faqCatData) {
        res = await getCategoryList();
        dispatchOnboardingForms({ type: FAQ_CATEGORY, payload: res?.data?.data });
      }
      if (pathSegments[1] === 'faqs') {
        const data = res?.data?.data || faqCatData;
        const isValidParam =
          data?.length > 0 &&
          pathSegments?.length > 2 &&
          data.some((item) => item.name.toLowerCase() === params.type.toLowerCase());

        if (!isValidParam) {
          navigate(`/faqs/${data[0].name}`, { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLegalCategory = async () => {
    try {
      let res = '';
      if (pathSegments[1] === 'legal' && !legalCatData) {
        res = await getviewAllLegal('1000');
        dispatchOnboardingForms({ type: LEGAL_CATEGORY, payload: res?.data?.data });
      }
      if (pathSegments[1] === 'legal') {
        const data = res?.data?.data || legalCatData;
        const isValidParam =
          pathSegments?.length > 2 && data.some((item) => item.legalTitle.toLowerCase() === params.type.toLowerCase());
        if (!isValidParam) {
          navigate(`/legal/${data[0].legalTitle}`, { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkPwaWithMob();
    getFaqCategory();
    getLegalCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      <div className={`${isShowHeaderFooter() ? '!block' : '!hidden'}`}>
        {<Header open={open} setOpen={setOpen} />}
      </div>
      <div className={`${isMobile && 'min-h-screen bg-transparent'}`}>
        {isMobile ? <MobileLayout /> : <DesktopLayout />}
      </div>

      {isShowHeaderFooter() && <Footer />}
      <FooterNavbar />
    </>
  );
};

const MobileLayout = () => {
  const location = useLocation();
  const { onboardingForms } = useBlackJetContext();
  const {
    aboutUsLocationIds = [],
    careersLocationIds = [],
    contactUsLocationIds = [],
    // faqsLocationIds = [],
    investorsLocationIds = [],
    // legalLocationIds = [],
    mediaPressLocationIds = [],
    newsLocationIds = [],
  } = onboardingForms?.savedLocation || {};
  const pathname = location.pathname;

  const isRoutesVisibleMobile = () => {
    switch (pathname) {
      case ROUTE_LIST.ABOUT_US:
        return isMobileValidRoute(aboutUsLocationIds);
      case ROUTE_LIST.CAREERS:
        return isMobileValidRoute(careersLocationIds);
      case ROUTE_LIST.CONTACT_US:
        return isMobileValidRoute(contactUsLocationIds);
      // case ROUTE_LIST.FAQ:
      //   return isMobileValidRoute(faqsLocationIds);
      case ROUTE_LIST.INVESTORS:
        return isMobileValidRoute(investorsLocationIds);
      // case ROUTE_LIST.LEGAL:
      //   return isMobileValidRoute(legalLocationIds);
      case ROUTE_LIST.MEDIA:
        return isMobileValidRoute(mediaPressLocationIds);
      case ROUTE_LIST.NEWS:
        return isMobileValidRoute(newsLocationIds);
      default:
        return true;
    }
  };

  if (!onboardingForms?.savedLocation) {
    return (
      <div className='loader-wrap-editor'>
        <LinearProgress color='#000' />
      </div>
    );
  }
  return isRoutesVisibleMobile() ? (
    <Outlet />
  ) : (
    <>
      <Header open={false} setOpen={() => {}} />
      <NotFound />
      <Footer />
    </>
  );
};

const DesktopLayout = () => {
  const location = useLocation();
  const { onboardingForms } = useBlackJetContext();
  const {
    aboutUsLocationIds = [],
    careersLocationIds = [],
    contactUsLocationIds = [],
    faqsLocationIds = [],
    investorsLocationIds = [],
    legalLocationIds = [],
    mediaPressLocationIds = [],
    newsLocationIds = [],
  } = onboardingForms?.savedLocation || {};
  const pathname = location.pathname;

  const isRoutesVisibleDesktop = () => {
    switch (pathname) {
      case ROUTE_LIST.ABOUT_US:
        return isDesktopValidRoute(aboutUsLocationIds);
      case ROUTE_LIST.CAREERS:
        return isDesktopValidRoute(careersLocationIds);
      case ROUTE_LIST.CONTACT_US:
        return isDesktopValidRoute(contactUsLocationIds);
      case ROUTE_LIST.FAQ:
        return isDesktopValidRoute(faqsLocationIds);
      case ROUTE_LIST.INVESTORS:
        return isDesktopValidRoute(investorsLocationIds);
      case ROUTE_LIST.LEGAL:
        return isDesktopValidRoute(legalLocationIds);
      case ROUTE_LIST.MEDIA:
        return isDesktopValidRoute(mediaPressLocationIds);
      case ROUTE_LIST.NEWS:
        return isDesktopValidRoute(newsLocationIds);
      default:
        return true;
    }
  };
  if (!onboardingForms?.savedLocation) {
    return (
      <div className='loader-wrap-editor'>
        <LinearProgress color='#000' />
      </div>
    );
  }
  return isRoutesVisibleDesktop() ? <Outlet /> : <NotFound />;
};

export default CommonLayout;
