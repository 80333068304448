import React, { useState, useEffect, useRef } from 'react';

const CustomEditor = ({ onChange = () => {} }) => {
  const [QuillEditor, setQuillEditor] = useState(null);
  const [QuillDeltaToHtmlConverter, setQuillDeltaToHtmlConverter] = useState(null);
  const [value, setValue] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Dynamically import both Quill and QuillDeltaToHtmlConverter
      import('react-quill-new').then((mod) => setQuillEditor(() => mod.default));
      import('quill-delta-to-html').then((mod) =>
        setQuillDeltaToHtmlConverter(() => mod.QuillDeltaToHtmlConverter),
      );
    }
  }, []);

  const toolbarOptions = {
    container: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['image'],
    ],
  };

  const handleChange = (value, delta, source, editor) => {
    setValue(value);
    if (QuillDeltaToHtmlConverter) {
      const converter = new QuillDeltaToHtmlConverter(editor.getContents().ops, {});
      const html = converter.convert();
      onChange(html);
    }
  };

  if (!QuillEditor || !QuillDeltaToHtmlConverter) return <p>Loading editor...</p>;

  return (
    <QuillEditor
      ref={quillRef}
      modules={{ toolbar: toolbarOptions }}
      placeholder='Type your enquiry here'
      theme='snow'
      value={value}
      onChange={handleChange}
    />
  );
};

export default CustomEditor;
