import { useMediaQuery } from '@mui/material';
import { flightChair } from 'assets';
import { Img } from 'components';
import { TextAccent } from 'components/Text';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';

/**
 * Component for the "New Way to Fly Private" section of the home page.
 *
 * This component renders a section that displays a picture of a plane on the
 * left and a list of features on the right. The features are displayed in a
 * parallax effect, with the text and images moving at different speeds as the
 * user scrolls.
 *
 * The component takes no props.
 *
 * @returns {React.ReactElement} The JSX element for the "New Way to Fly Private"
 * section.
 */
const FlyPrivateSection = () => {
  const isMobile = useMediaQuery('(max-width : 699px)');
  const cardHeadingUnlimited = 'Unlimited Flights';
  const cardDescUnlimited = `All-you-can-fly`;

  const cardHeadingDepart = 'Depart from Private Terminal Lounges';
  const cardDescDepart = `With us, you only need to arrive 15 minutes before departure — because your time matters`;

  return (
    <div>
      <div className='new-way-main'>
        <div className='new-way-img'>
          <Img className='side-img' src={flightChair} alt='picture' />
          {!isMobile && (
            <div className='desk-section-div'>
              <div className='parallax-div'>
                <Parallax speed={20}>
                  <div className='new-way-div'>
                    <div className='new-way-inner'>
                      <Img
                        src='https://assets.blackjet.au/images/img_icons8infinity.svg'
                        alt='icons8infinity'
                      />
                      <div className='new-way-txt'>
                        <h3 className='new-way-header'>{cardHeadingUnlimited}</h3>
                        <p>{cardDescUnlimited}</p>
                      </div>
                    </div>
                  </div>
                  <div className='new-way-div new-way-outer'>
                    <div className='new-way-inner'>
                      <img src='https://assets.blackjet.au/images/terminal.svg' alt='calendar' />
                      <div className='new-way-txt'>
                        <h3 className='new-way-header'>{cardHeadingDepart}</h3>
                        <p className='new-way-inner-desc'>{cardDescDepart}</p>
                      </div>
                    </div>

                    <p className='new-way-outer-desc '>{cardDescDepart}</p>
                  </div>
                </Parallax>
              </div>
            </div>
          )}
          {isMobile && (
            <Parallax translateX={['200px', '-100px']}>
              <div className='mobile-section-div'>
                <div className='parallax-div '>
                  <div className='new-way-div unlimited-flight new-way-outer'>
                    <div className='new-way-inner'>
                      <img
                        src='https://assets.blackjet.au/images/img_icons8infinity.svg'
                        alt='icons8infinity'
                      />
                      <div className='new-way-txt'>
                        <h3 className='new-way-header'>{cardHeadingUnlimited}</h3>
                        <p className='new-way-inner-desc'>{cardDescUnlimited}</p>
                      </div>
                    </div>
                    <p className='new-way-outer-desc text-center'>{cardDescUnlimited}</p>
                  </div>

                  <div className='new-way-div private-terminal new-way-outer'>
                    <div className='new-way-inner '>
                      <img src='https://assets.blackjet.au/images/terminal.svg' alt='calendar' />
                      <div className='new-way-txt'>
                        <h3 className='new-way-header'>{cardHeadingDepart}</h3>
                        <p className='new-way-inner-desc'>{cardDescDepart}</p>
                      </div>
                    </div>

                    <p className='new-way-outer-desc '>{cardDescDepart}</p>
                  </div>
                </div>
              </div>
            </Parallax>
          )}
        </div>
        <div className='new-way-private'>
          <div className='new-txt'>
            {isMobile ? (
              <h1 className='new-way-txt new-way-heading'>New Way to Fly Private</h1>
            ) : (
              <>
                <h1 className='new-way-txt new-way-heading'>
                  New Way to
                  <br />
                  Fly Private
                </h1>
              </>
            )}
            <h5>
              <TextAccent> Unlimited all-you-can-fly </TextAccent>
              <h5>
                <TextAccent> Yes, you read that right — fly as often as you want </TextAccent> with
                a <TextAccent>Black Jet membership</TextAccent> for one{' '}
                <TextAccent> fixed monthly price </TextAccent>. No limits, no hassle. Just
                effortless booking in the Black Jet app
              </h5>
              <h5>
                We’re launching with at least three weekly round trips between{' '}
                <TextAccent>Sydney and Melbourne</TextAccent>, soon expanding to{' '}
                <TextAccent>multiple daily flights</TextAccent> — with even more destinations on the
                horizon
              </h5>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlyPrivateSection;
