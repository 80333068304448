import HOMEDesktopTabletLandscapeHomecontent from 'components/Home';
import { Entermobilegetapp } from 'components/Popup';
import React from 'react';

const MemoHomeApp = () => {
  return (
    <div>
      <Entermobilegetapp />
      <div className='relative flex flex-col w-full mx-auto bg-gray-900 font-hauora sm:bg-inherit'>
        <HOMEDesktopTabletLandscapeHomecontent isPwa />
      </div>
    </div>
  );
};

export default MemoHomeApp;
