import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
export const HomeMeta = () => {
  return (
    <Helmet>
      <title>Black Jet - Australia's First and Only Personal Aviation</title>
      <meta
        name='description'
        content="Fly Freely. Unlimited Sydney-Melbourne flights for a flat monthly price. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience"
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta
        property='og:title'
        content="Black Jet - Australia's First and Only Personal Aviation"
      />
      <meta
        property='og:description'
        content="Fly Freely. Unlimited Sydney-Melbourne flights for a flat monthly price. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience"
      />
      <meta
        property='og:image'
        content='https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png'
      />
      <meta property='og:image:type' content='image/png' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta
        property='og:image:alt'
        content="Black Jet - Fly Freely. Experience Australia's first and only personal aviation service"
      />
      <meta property='og:url' content='https://blackjet.au' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Black Jet' />
      <meta property='og:locale' content='en_AU' />
      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta
        name='twitter:title'
        content="Black Jet - Australia's First and Only Personal Aviation"
      />
      <meta
        name='twitter:description'
        content="Fly Freely. Unlimited Sydney-Melbourne flights for a flat monthly price. Arrive a mere 15 minutes before departure at our exclusive private terminal-say goodbye to busy terminals and lengthy lines. As a Black Jet member, you're introduced to a transformative, hassle-free flying experience"
      />
      <meta
        name='twitter:image'
        content='https://assets.blackjet.au/Black-Jet-fly-freely-unlimited-flights-membership-australia-first.png'
      />
      <meta
        name='twitter:image:alt'
        content="Black Jet - Fly Freely. Experience Australia's first and only personal aviation service"
      />
      <meta name='twitter:site' content='@joinBlackJet' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au' />
    </Helmet>
  );
};
export const FaqsMeta = () => {
  const param = useParams();
  const paramType = param?.type || 'FAQs';
  const faqUrl = param?.type
    ? `https://blackjet.au/faqs/${param?.type}`
    : 'https://blackjet.au/faqs';
  return (
    <Helmet>
      <title>{paramType} | BlackJet</title>
      <meta
        name='description'
        content='Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more'
      />
      <meta
        name='keywords'
        content='BlackJet, FAQs, Black Jet, membership, subscription, private Aviation, popular questions, safety & security, booking flights, in-flight experience, airport, what lies ahead'
      />
      <meta property='og:title' content={`Black Jet | ${paramType}`} />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta
        property='og:description'
        content='Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={faqUrl} />
      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={`Black Jet | ${paramType}`} />
      <meta
        name='twitter:description'
        content='Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more'
      />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href={faqUrl} />
    </Helmet>
  );
};
export const AboutUsMeta = () => {
  return (
    <Helmet>
      <title>About Us | BlackJet</title>
      <title>About Us | Black Jet</title>
      <meta
        name='description'
        content='Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach'
      />
      <meta
        name='keywords'
        content='BlackJet, CASR, CASA, Black Jet, Part 135, Australian company, based in Sydney, Bankstown airport'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Black Jet | About Us' />
      <meta
        property='og:description'
        content='Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach'
      />
      <meta property='og:url' content='https://blackjet.au/aboutus' />
      <meta property='og:type' content='website' />
      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='Black Jet | About Us' />
      <meta
        name='twitter:description'
        content='Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach'
      />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/aboutus' />
    </Helmet>
  );
};
export const VirtualTourMeta = () => {
  return (
    <Helmet>
      <title>Virtual Tour | BlackJet</title>
      <title>Virtual Tour | Black Jet</title>
      <meta name='description' content='Explore the interior of our Black Jet plane' />
      <meta
        name='keywords'
        content='BlackJet, virtual tour, 3D tour, BlackJet cabin, BlackJet interior, private jet cabin, Black Jet, private jet interior, panorama, panoramic'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Black Jet | Virtual Tour' />
      <meta property='og:description' content='Explore the interior of a Black Jet plane' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/virtual-tour' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/virtual-tour' />
    </Helmet>
  );
};
export const BookingMeta = () => {
  return (
    <Helmet>
      <title>Booking | BlackJet</title>
      <title>Booking | Black Jet</title>
      <meta
        name='description'
        content='Experience our seamless booking process—select your seats with a simple drag-and-drop, all at your fingertips'
      />
      <meta
        name='keywords'
        content='BlackJet app, BlackJet, booking, Black Jet, simple, seamless, drag-and-drop, fingertips, Black Jet app'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Black Jet | Booking' />
      <meta
        property='og:description'
        content='Experience our seamless booking process—select your seats with a simple drag-and-drop, all at your fingertips'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/booking' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/booking' />
    </Helmet>
  );
};
export const ProfileMeta = () => {
  return (
    <Helmet>
      <title>Profile | BlackJet</title>
      <title>Profile | Black Jet</title>
      <meta
        name='description'
        content='Create an account or log in to explore the one-of-a-kind experience of the exclusive Black Jet app'
      />
      <meta
        name='keywords'
        content='BlackJet app, BlackJet, exclusive, Black Jet, unique, login, log-in, signup, sign-up, profile, Black Jet app'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Black Jet | Profile' />
      <meta
        property='og:description'
        content='Create an account or log in to explore the one-of-a-kind experience of the exclusive Black Jet app'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/profile' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/profile' />
    </Helmet>
  );
};
export const ContactUsMeta = () => {
  return (
    <Helmet>
      <title>Contact Us | BlackJet</title>
      <title>Contact Us | Black Jet</title>
      <meta
        name='description'
        content='Get in touch with Black Jet’s team for assistance with memberships, the Black Jet app, or general inquiries'
      />
      <meta
        name='keywords'
        content='BlackJet, Contact Us, memberships, Black Jet App, general inquiries, BlackJet App'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Black Jet | Contact Us' />
      <meta
        property='og:description'
        content='Get in touch with Black Jet’s team for assistance with memberships, the Black Jet app, or general inquiries'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/contactus' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/contactus' />
    </Helmet>
  );
};
export const LegalMeta = () => {
  const param = useParams();
  const paramType = param?.type || 'Legal';
  const legalUrl = param?.type
    ? `https://blackjet.au/legal/${param?.type}`
    : 'https://blackjet.au/legal';
  return (
    <Helmet>
      <title>{paramType} | BlackJet</title>
      <title>{paramType} | Black Jet</title>
      <meta name='description' content="Black Jet's Privacy Policy, Terms of Use, and more" />
      <meta name='keywords' content='BlackJet, Legal, Terms of Use, Privacy Policy, Black Jet' />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content={`Black Jet | ${paramType}`} />
      <meta
        property='og:description'
        content="Black Jet's Privacy Policy, Terms of Use, and more"
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={legalUrl} />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href={legalUrl} />
    </Helmet>
  );
};
export const CareersMeta = () => {
  return (
    <Helmet>
      <title>Careers | BlackJet</title>
      <title>Careers | Black Jet</title>
      <meta
        name='description'
        content='Join our team in shaping the future of aviation—more personal, stress-free, and flexible than ever'
      />
      <meta
        name='keywords'
        content='BlackJet, careers, team, opportunities, jobs, openings, Black Jet, Pilots, Captain, First Officer, direct entry'
      />
      {/* <!-- Open Graph Meta Tags --> */}
      <meta property='og:title' content='Careers | Black Jet' />
      <meta
        property='og:description'
        content='Join our team in shaping the future of aviation—more personal, stress-free, and flexible than ever'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/careers' />
      {/* <!-- Canonical Link --> */}
      <link rel='canonical' href='https://blackjet.au/careers' />
    </Helmet>
  );
};
