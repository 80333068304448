import { useBlackJetContext } from 'context/OnboardingContext';
import React, { useEffect, useRef, useState } from 'react';
// import { Helmet } from 'react-helmet-async';
import { getviewLegal } from 'services/api';

/**
 * This component renders a legal card based on the id passed as a prop.
 * It fetches the legal content from the server using the getviewLegal function
 * and renders it in a div with the class 'static-pages-content'.
 * If the id is not defined, it renders a 'Loading...' message.
 *
 * @param {String} id - The id of the legal content to be rendered.
 * @returns A React component that renders the legal content.
 */
export const LegalCardsById = ({ id }) => {
  // console.log('ABC', { abc: onboardingForms.legalDetails[0] });
  const { onboardingForms } = useBlackJetContext();
  const singleDataByServer = onboardingForms.legalDetails;
  const [selectedLegal, setSelectedLegal] = useState(singleDataByServer);
  const [isServer, setIsServer] = useState(true);
  const iframeRef = useRef(null);
  const renderRef = useRef(null);
  const viewLegalById = async () => {
    renderRef.current = id;
    setSelectedLegal(null);
    try {
      const res = await getviewLegal(id);
      console.log(res, 'this_is_response');
      setSelectedLegal(res?.data?.data);

      // setSelectedId(res?.data?.data[0]._id || {});
    } catch (error) {
      console.log(error);
      setSelectedLegal(null);
    }
  };

  // console.log(id)

  useEffect(() => {
    if (id !== '' && id !== undefined && renderRef.current !== id) {
      viewLegalById();
      setIsServer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      // Adjust the height after the iframe loads
      const adjustHeight = () => {
        const iframeDocument = iframe.contentWindow?.document;
        if (iframeDocument) {
          // Set the iframe's body overflow to hidden
          iframeDocument.body.style.overflow = 'hidden';
          iframeDocument.body.style.background = 'transparent';
          // give margin 0, and padding 0
          iframeDocument.body.style.margin = '0';
          // 4px padding bottom
          iframeDocument.body.style.padding = '0';
          // attach a class to the iframe body
          iframeDocument.body.classList.add('legal-iframe-body');
          //  add meta tag in the iframe head "<meta name="format-detection" content="telephone=no">"
          const metaTag = document.createElement('meta');
          metaTag.name = 'format-detection';
          metaTag.content = 'telephone=no';
          iframeDocument.head.appendChild(metaTag);
          // delete href from a tag
          const aTags = iframeDocument.querySelectorAll('a');
          aTags.forEach((aTag) => {
            aTag.removeAttribute('href');
          });

          // Dynamically adjust the iframe height
          iframe.style.height = iframeDocument.body.scrollHeight + 100 + 'px';
        }
      };

      iframe.addEventListener('load', adjustHeight);

      // Clean up the event listener
      return () => {
        iframe.removeEventListener('load', adjustHeight);
      };
    }
  }, [selectedLegal]);

  if (!selectedLegal) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <Helmet>
        <script className='structured-data-list' type='application/ld+json'>
          {JSON.stringify(legalJsonLd())}
        </script>
      </Helmet> */}
      <div className="flex flex-col w-full font-['Hauora'] items-start">
        <div className='legal-title'>{selectedLegal?.legal_title}</div>

        {isServer && (
          <div
            className='static-pages-content'
            id='render-text'
            dangerouslySetInnerHTML={{ __html: selectedLegal.legalContent }}
          ></div>
        )}
        {!isServer && (
          <iframe
            ref={iframeRef}
            className='static-pages-content'
            id='render-text'
            srcDoc={selectedLegal.legalContent}
            style={{
              width: '100%',
              border: 'none',
              overflow: 'hidden', // Prevent unnecessary scrollbars
            }}
            title='Legal Content'
          />
        )}
      </div>
    </>
  );
};

// export default Membership;
