import { useState, useEffect } from 'react';

function useIsUserInteract() {
  const [isInteracted, setIsInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => setIsInteracted(true);

    window.addEventListener('mousedown', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);
    window.addEventListener('scroll', handleUserInteraction);

    return () => {
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
      window.removeEventListener('scroll', handleUserInteraction);
    };
  }, []);

  return isInteracted;
}

export default useIsUserInteract;
