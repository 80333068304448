import { LinearProgress } from '@mui/material';
import React from 'react';
/**
 * A simple loader component that takes up the full screen height.
 *
 * @return {React.ReactElement} A div with class 'h-screen'.
 */
const Loader = () => {
  return (
    <div className='h-screen'>
      <div className='loader-wrap-editor'>
        <LinearProgress color='#000' />
      </div>
    </div>
  );
};

export default Loader;
