import { hydrateRoot } from 'react-dom/client';
import './styles/index.css';
import './styles/style.scss';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router';
const rootElement = document?.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const helmetContext = {};

const app = (
  <StrictMode>
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
        <App
          membershipDetails={{}}
          legalDetails={[]}
          faqDetails={{}}
          careerDetails={{}}
          singleJobDetails={[]}
        />
      </HelmetProvider>
    </BrowserRouter>
  </StrictMode>
);

hydrateRoot(rootElement, app, {
  onRecoverableError: () =>
    // error, errorInfo
    {
      // ! this is temporary for pass still have hydration error
      // console.log('Caught_error', error, errorInfo.componentStack);
    },
});
