import React, { useRef, useState, useEffect } from 'react';
import { getFaqQuestions } from 'services/api';

import { useMediaQuery } from '@mui/material';
// import { handleFaqSlide, isFaqOpened } from 'helpers';
import { Entermobilegetapp } from 'components/Popup';
import { useNavigate, Link, useParams } from 'react-router';
import { isEmpty } from 'helpers/common';
// import FramerMotion from 'components/animations/FramerMotion';
// import { ROUTE_LIST } from 'routes/routeList';
// import { Helmet } from 'react-helmet-async';
import { FaqsMeta } from 'components/meta/BlackjetMeta';
import { FaqStructureData } from 'components/meta/HomeStructureData';
// import useQueryParams from 'Hook/useQueryParams';
import { useBlackJetContext } from 'context/OnboardingContext';
import { yearTradeMark } from 'utils/moment';

/**
 * The Faq component renders a list of FAQs from the API and allows the user to select a category.
 * It also renders a form to submit an enquiry.
 *
 * @return {ReactElement} The Faq component.
 */
const Faq = () => {
  const { onboardingForms } = useBlackJetContext();
  const [questions, setQuestions] = useState(onboardingForms?.faqDetails?.selectedCategory || []);
  const isSSR = import.meta.env.SSR;
  const categoryList = isSSR
    ? onboardingForms?.faqDetails.categories
    : onboardingForms?.faqCategory;

  // console.log(onboardingForms?.faqDetails, 'question__1212');
  // const [selectedCategory, setSelectedCategory] = useState({});
  const [limit] = useState(100);
  const isTabMobile = useMediaQuery('(max-width : 530px)');
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  // const location = useLocation();
  const param = useParams();
  const queryParams = param.type || '';
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const typeToCategory =
    categoryList?.find((category) => category?.name === queryParams) || categoryList?.[0];
  const refId = useRef(null);
  // console.log(typeToCategory, '__typeToCategory__');

  // api simulation

  // useEffect(() => {
  //   // if (onboardingForms?.faqCategory) {
  //   setCategoryList((pre) => {
  //     if (pre?.length === 0 || !pre) {
  //       return onboardingForms?.faqCategory;
  //     } else {
  //       return pre;
  //     }
  //   });
  //   // }
  // }, []);

  // useEffect(() => {
  //   if (onboardingForms?.faqCategory) {
  //     (async () => {
  //       try {
  //         const res = onboardingForms?.faqCategory;
  //         // setCategoryList(res);
  //         const typeToCategory = res?.find((category) => category?.name === queryParams);
  //         setSelectedCategory(typeToCategory || res[0] || {});
  //         getFaqQuestionsInfo(typeToCategory?._id || res.data.data[0]?._id);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getFaqQuestionsInfo = async (id) => {
    refId.current = id;
    if (!id) return;
    try {
      const res = await getFaqQuestions(id, limit, false);
      // console.log(res.data);
      const data = res?.data?.data;
      // console.log('data1212', data);
      setQuestions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (typeToCategory?._id !== refId.current) {
      getFaqQuestionsInfo(typeToCategory?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeToCategory]);

  // useEffect(() => {
  //   if (isTabMobile && location.pathname === ROUTE_LIST.FAQ) {
  //     if (!isFaqOpened()) {
  //       handleFaqSlide(true);
  //     }
  //   }
  // }, [isTabMobile, location.pathname]);

  // const handleFaqClicked = (category) => {
  //   setSelectedCategory(category);
  //   getFaqQuestionsInfo(category?._id);
  // };

  /**
   * Handles the closing of the FAQ page, navigating either to the previous
   * page in the history stack (if available) or to the landing page.
   */
  const handleCloseFaq = () => {
    if (window?.history.length > 2 && !isEmpty(window?.history.state)) {
      // Check for at least two entries (SPA + initial visit)
      navigate(-1);
    } else {
      navigate('/'); // Redirect to landing page if no previous history
    }

    // handleFaqSlide(false);
  };

  // console.log(queryParams, 'this_is_type');
  // useEffect(() => {
  //   const typeToCategory = categoryList?.find((category) => category?.name === queryParams);
  //   console.log(typeToCategory, 'typeToCategory');
  //   if (typeToCategory) {
  //     handleFaqClicked(typeToCategory);
  //   }
  // }, [queryParams]);

  return (
    <>
      <FaqStructureData selectedTab={typeToCategory} selectedTabData={questions} />

      {/* <FramerMotion key={1}> */}
      <FaqsMeta />

      <Entermobilegetapp />
      <div id='mobile-faq' className='faq-main-wrapper' onWheel={(e) => e.stopPropagation()}>
        <div className='faq-heading mobile-hide'>Frequently Asked Questions</div>
        <div className='faq-heading faqm fixed-header-pages sticky-tab'>
          <img
            className='close-btn'
            src='https://assets.blackjet.au/images/close-icon-white.svg'
            alt='close-icon-white'
            onClick={handleCloseFaq}
          />
          <span className='mob-faq-heading'>FAQs</span>
          <div id='FAQCategoriesRoot' className='faq-tabs-wrap'>
            {categoryList?.map((category) => {
              return (
                <Link to={`/faqs/${category?.name}`} aria-label={category?.name}>
                  <div
                    key={category?._id}
                    id='FAQSections'
                    className={` ${
                      category._id === typeToCategory?._id ? ' !bg-[#616161] ' : ' bg-[black] '
                    } faq-card`}
                    // onClick={() => handleFaqClicked(category)}
                  >
                    <div className='faq-img'>
                      {' '}
                      <img src={category?.image} alt='FAQIcons' id='FAQIcons' />
                    </div>
                    <div className='faq-card-header'>{category?.name}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className='card-grid-section faq-card-wapper'>
          {questions?.map((data, index) => {
            return (
              <div
                className={`faq-card-it ${isTabMobile ? 'mobile-faq-container' : ''}`}
                key={index}
              >
                <div
                  id={`QRoot-${index}`}
                  className={`accordion-item ${
                    !isTabMobile || activeIndex === index ? 'active' : ''
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className='ques-f'>
                    <div className='ques-txt'>{data?.question}</div>
                    <img
                      src='https://assets.blackjet.au/images/down-arrow.svg'
                      alt='Vector'
                      className={`arrow-icon ${activeIndex === index ? 'expanded' : ''}`}
                    />
                    <img
                      src='https://assets.blackjet.au/images/chevron-up.svg'
                      alt='Down Arrow'
                      className={`down-arrow-icon ${activeIndex === index ? 'collapsed' : ''}`}
                    />
                  </div>

                  {(!isTabMobile || activeIndex === index) && <hr className='divider' />}

                  <article>
                    <div
                      className={`ans-txt ${!isTabMobile || activeIndex === index ? 'show' : ''}`}
                      dangerouslySetInnerHTML={{ __html: data?.answer }}
                    ></div>
                  </article>
                </div>
              </div>
            );
          })}
        </div>
        <span className='bottom-trademark'>{yearTradeMark()}</span>
      </div>
      {/* </FramerMotion> */}
    </>
  );
};

export default Faq;
