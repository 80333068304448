import { useMediaQuery } from '@mui/material';
import { tour } from 'assets';
import { Text } from 'components';
import { VirtualTourMeta } from 'components/meta/BlackjetMeta';
import { TextAccent } from 'components/Text';
import Virtualview from 'components/Virtualview';
import useOutsideClick from 'Hook/useOutsideClick';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
// import { useDetectClickOutside } from 'react-detect-click-outside';

/**
 * @function TourSection
 * @description Component for Tour Section
 * @returns {jsx} A JSX element
 * @example
 * <TourSection />
 */
const TourSection = () => {
  const [visible, setVisible] = useState(false);
  // const ref = useDetectClickOutside({ onTriggered: closePopup });
  // const [mouseDown, setMouseDown] = useState(false);

  const [isVirtualviewVisible, setVirtualviewVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:699px)');

  /**
   * @function handleButtonClick
   * @description Toggle the visibility of the virtual view and the tour section, and scroll to the corresponding container.
   * @example
   * <TourSection />
   */
  const handleButtonClick = () => {
    setVisible((prev) => {
      if (prev) {
        toast.dismiss();
      }
      return !prev;
    });
    const element = document?.getElementById(
      isVirtualviewVisible ? 'buttonContainer' : 'frameConatiner',
    );

    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 500);
    }
    setVirtualviewVisible(!isVirtualviewVisible);
  };
  const handleClosePopup = () => {
    toast.dismiss();
    setVisible(false);
    const element = document?.getElementById(
      isVirtualviewVisible ? 'buttonContainer' : 'frameConatiner',
    );

    if (element && isVirtualviewVisible) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 500);
    }
    setVirtualviewVisible(false);
  };
  useOutsideClick('buttonContainer', handleClosePopup);

  const handleAutoScroll = () => {
    try {
      if (location.pathname !== '/virtual-tour') return;
      if (isMobile) {
        navigate('/booking');
        return;
      }
      const activeLink = 'buttonContainer';
      if (activeLink) {
        const targetElement = document?.getElementById(activeLink);
        if (targetElement) {
          const offset = 200; // Desired margin from the top
          const elementPosition = targetElement.getBoundingClientRect().top + window?.scrollY;
          const offsetPosition = elementPosition - offset;

          window?.scrollTo({
            top: offsetPosition,
            behavior: 'smooth', // Smooth scrolling
          });
        }
      }
      handleButtonClick();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleAutoScroll();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {location.pathname === '/virtual-tour' && <VirtualTourMeta />}
      <div id='buttonContainer' className='tour-section-main'>
        <div className='tour-txt-div' orientation='horizontal'>
          <div className='text-section sm:border-b-2 sm:border-[#333]'>
            <h2>What you fly in</h2>
            <div className='list-wrap'>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload' />
                <p>
                  <TextAccent>Elevate your flying experience </TextAccent>
                  in our <TextAccent>luxurious 8-seat cabin</TextAccent>, a masterful creation
                  brought to life by the design maestros at <TextAccent>BMW Designworks</TextAccent>
                </p>
              </div>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload_One' />
                <p>
                  Thanks to the <TextAccent>fuel-efficient design</TextAccent> of the turbine-driven
                  propeller, flying on our aircraft <TextAccent>consumes less fuel</TextAccent> than
                  if you were to <TextAccent>drive the route</TextAccent>, reducing your carbon
                  footprint
                </p>
              </div>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload_Two' />
                <p>
                  While the flight duration is, on average, a mere 20 minutes longer than that of
                  public air transport, <TextAccent>our private terminals</TextAccent> ensure you
                  <TextAccent> save a total of 120 minutes</TextAccent> on each round trip
                </p>
              </div>
            </div>
          </div>
          <div className='text-section'>
            <h2>Proven safety record</h2>
            <div className='list-wrap'>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload' />
                <p>
                  With <TextAccent> safe operation</TextAccent> of over{' '}
                  <TextAccent>seven million flight hours </TextAccent>
                  and 1,700 aircrafts, the aircraft <TextAccent>
                    {' '}
                    touts a safety record{' '}
                  </TextAccent>{' '}
                  on par with <TextAccent> twin-engine jets </TextAccent>
                </p>
              </div>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload_One' />
                <p>
                  <TextAccent>Powered by a jet engine</TextAccent>, the turbine-driven propeller
                  combines <TextAccent>jet reliability</TextAccent> with{' '}
                  <TextAccent>propeller fuel efficiency</TextAccent>
                </p>
              </div>
              <div className='list-div '>
                <img src='https://assets.blackjet.au/images/img_upload.svg' alt='upload_Two' />
                <p>
                  Even though our aircraft is certified for a single pilot, our policy is to{' '}
                  <TextAccent> operate with two pilots </TextAccent> for{' '}
                  <TextAccent>all flights</TextAccent>, ensuring an{' '}
                  <TextAccent>apex of safety and redundancy </TextAccent>
                  when you fly
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id='frameConatiner' className='w-screen'>
          <div
            className={`overflow-hidden h-0 transition-[height] duration-[1000ms] ease-in-out ${
              isVirtualviewVisible ? '!h-screen' : ''
            }`}
            // ref={ref}
            // onMouseDown={() => setMouseDown(true)}
            // onMouseLeave={()=>setMouseDown(false)}
            // onMouseUp={() => setMouseDown(false)}
          >
            <Virtualview
              visible={visible}
              setVisible={setVisible}
              handleButtonClick={handleButtonClick}
            />
          </div>
        </div>
        <div className='relative w-full tour-banner-container'>
          <img
            className='tour-banner desktop-img opacity-80 h-full mx-auto object-cover rounded-[1px] w-full md:w-full'
            alt='airplane-image'
            src={tour}
          />
          <div
            onClick={handleButtonClick}
            className={`${
              isVirtualviewVisible ? 'hidden' : ''
            } absolute cursor-pointer sm:hidden rounded-full bg-[#141414] top-[calc(0%-60px)] bg-black left-[calc(50%-60px)] md:h-[118px] h-[120px] right-[0] w-[120px]`}
          >
            <div className='absolute bg-white-A700 h-[88px] inset-[0] justify-center m-auto rounded-[50%] w-[88px]'></div>
            <div className='absolute border border-solid border-white-A700 flex flex-col h-full inset-[0] items-center justify-center m-auto p-6 sm:px-5 rounded-[50%] w-[120px]'>
              <Text
                className='tour-button my-4 4k:!font-bold 4k:!text-[14px] 4k:font-hauora text-center text-sm w-[99%] sm:w-full'
                size='txtHauoraBold14'
              >
                Tour the plane
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourSection;
