import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
// import { getviewAllLegal } from 'services/api';
// import useQueryParams from 'Hook/useQueryParams';
import { Entermobilegetapp } from 'components/Popup';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router';
import { useMediaQuery } from '@mui/material';
// import FramerMotion from 'components/animations/FramerMotion';
import { LegalCardsById } from './LegalCardsById';
import { LegalMeta } from 'components/meta/BlackjetMeta';
import { useBlackJetContext } from 'context/OnboardingContext';
import { yearTradeMark } from 'utils/moment';

/**
 * A component that renders a legal page with a list of legal documents.
 *
 * It fetches the list of legal documents from the API and renders a list of
 * tabs with the document titles. When a tab is clicked, it fetches the
 * corresponding document from the API and renders it in a scrollable box.
 *
 * The component is responsive and changes its layout based on the screen size.
 *
 * It also handles the "Close" button at the top right corner of the page.
 *
 * @returns {React.ReactElement} A JSX element representing the legal page.
 */
const Legal = () => {
  // console.log('ABC', { abc: onboardingForms.legalDetails[0] });
  const { onboardingForms } = useBlackJetContext();
  const isSSR = import.meta.env.SSR;
  // const queryParams = useQueryParams();
  // const type = queryParams.type || '';
  const legalList = isSSR
    ? onboardingForms.legalDetails.viewAllLegal
    : onboardingForms.legalCategory;

  const navigate = useNavigate();
  // const location = useLocation();
  const param = useParams();
  const isMobile = useMediaQuery('(max-width : 699px)');

  const serverId = isSSR
    ? legalList?.find((ele) => ele?.legalTitle.toLowerCase().includes(param?.type?.toLowerCase()))
    : legalList?.find((ele) =>
        ele?.legalTitle.toLowerCase().includes(param?.type?.toLowerCase()),
      ) || onboardingForms?.legalCategory?.at(0);
  const selectedId = serverId?._id;
  console.log(selectedId, 'selectedId');
  // const limit = 100;

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await getviewAllLegal(limit);
  //       setLegalList(res?.data?.data);
  //       if (res?.data?.data && res?.data?.data[0]) {
  //         const findType = res?.data?.data.find((ele) =>
  //           ele?.legalTitle.toLowerCase().includes(type.toLowerCase()),
  //         );
  //         setSelectedId(findType?._id || res?.data?.data[0]._id || '');
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [type]);

  /**
   * Handles the "Close" button at the top right corner of the legal page.
   *
   * If the user has navigated to the legal page from another page in the app
   * (i.e., there is at least one entry in the browser's history stack), this
   * function will take the user back to the previous page by calling
   * `navigate(-1)`.
   *
   * If the user has navigated to the legal page directly (i.e., there are no
   * previous entries in the browser's history stack), this function will
   * redirect the user to the landing page by calling `navigate('/')`.
   */
  const handleCloseLegal = () => {
    if (window?.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/'); // Redirect to landing page if no previous history
    }
  };

  const handleTabChange = (event, newValue) => {
    const idToObject = legalList?.find((legal) => legal?._id === newValue);
    navigate(`/legal/${idToObject?.legalTitle}`);
  };

  const legalJsx = (
    <div className='common-scroll-mob sticky-tab'>
      <img
        className='close-btn'
        src='https://assets.blackjet.au/images/close-icon-white.svg'
        alt='close-icon-white'
        onClick={handleCloseLegal}
      />
      <h1 className='legal-heading fixed-header-pages main-legal-heading'>Legal</h1>

      {/* Flex wrapper with gap and left alignment */}
      <div className='main-tabs'>
        <Tabs
          value={selectedId}
          onChange={handleTabChange}
          orientation={isMobile ? 'horizontal' : 'vertical'}
          className=' flex-none tab-container'
          TabIndicatorProps={{ style: { display: 'none', transition: 'none' } }}
          sx={{ flexShrink: 0 }}
        >
          <div className='text-left w-full'>
            <h1 className='legal-heading fixed-header-pages desktop-legal-heading'>Legal</h1>
          </div>
          {legalList?.map((legal) => (
            <Tab
              key={legal?._id}
              value={legal?._id}
              aria-setsize={legalList.length}
              disableRipple
              label={
                <div className='text-left w-full  mobile-legal-links'>
                  {isMobile ? (
                    <div className='break-words'>{legal?.legalTitle}</div>
                  ) : (
                    <Link
                      to={`/legal/${legal?.legalTitle}`}
                      className='break-words legal-links'
                      aria-label={legal?.legalTitle}
                    >
                      {legal?.legalTitle}
                    </Link>
                  )}
                </div>
              }
              className={`${
                selectedId === legal._id ? ' !text-[#ffffff] tab-t ' : ' tab-t'
              } !bg-inherit mb-6`}
              sx={{ alignItems: 'flex-start' }} // Ensures text aligns to the left
            />
          ))}
        </Tabs>

        {/* This Box now has a flex-grow for dynamic sizing */}
        <Box className='faq-wrap-body legal-content-wrap flex-grow'>
          <LegalCardsById id={selectedId} />
        </Box>
      </div>
      <span className='bottom-trademark'>{yearTradeMark()}</span>
    </div>
  );

  return (
    <>
      {/* <FramerMotion key={location.pathname}> */}
      <LegalMeta />
      <div id='legal-mobile' aria-label='legal-mobile-page' className='legal min-h-screen'>
        <Entermobilegetapp />
        <div className='flex justify-between'></div>
        {!legalList && selectedId === '' ? <div>Loading...</div> : legalJsx}
      </div>
      {/* </FramerMotion> */}
    </>
  );
};

export default Legal;
